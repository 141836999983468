.part-info-container {
	display: flex;
	flex-direction: row;

	.part-info {
		flex: 0 1 auto;
	}

	&.alternate {
		margin: 3rem 0;
		justify-content: end;
	}
}

.part-info {
	background: white;
	border: solid 1px #ccc;
	border-radius: 5px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7), 0 5px 15px rgba(0, 0, 0, 0.1);
	display: flex;
	user-select: none;
	padding: 0.5rem;
	width: auto;

	.part-image {
		padding-top: 0.5rem;
		padding-right: 0.5rem;
	}

	.part-details {
		.part-number {
			font-size: 1.5rem;
		}

		.part-name {
			font-size: 1.25rem;
		}

		.part-subtitle {
			font-size: 1rem;
		}

		.part-code {
			font-family: "Source Code Pro", monospace;
			margin-top: 0.25rem;

			.fa-fw {
				margin-right: 0.1rem;
			}
		}
	}

	.part-xref {
		color: #3273dc;
		border-bottom: 1px dashed #3273dc;
		margin-bottom: 1px;
	}
}